import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import HomePageComponent from '../components/HomePage'

const HomePage = () => (
  <StaticQuery
    query={graphql`
      query HeroQuery {
        file(relativePath: {
          regex: "/main/"
        }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Layout><HomePageComponent {...data} /></Layout>}
  />
)

export default HomePage
